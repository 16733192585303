<template>
<div style="background-color: #1d4596; height: 100%;  <!-- background-image: url('/img/FichaLogo.png'); --> background-size: cover;">
  <v-main >
    <v-container  fluid >
      <v-layout align-center justify-center>
        <v-flex  md4>
          <v-card
          elevation="12"
            style="border-radius: 8px; max-width: 400px; background-color: #FFF;"
          >
            <v-toolbar
              style="background: #1d4596"
              height="120"
            >
              <v-toolbar-title style="color: #fff" align-center justify-center>
                <v-spacer></v-spacer>
                <img
                  src="/img/FichaLogo.png"
                  width="302"
                  height="124"
                  alt=""
                  srcset=""
                />
                <v-spacer></v-spacer>
              </v-toolbar-title>
              <v-spacer />
            </v-toolbar>
            <v-card-text>
              <v-form>
                    <label for="" style="margin-bottom:20px;color:black;">Selecione uma Tag para este Dispositivo</label>
                    <v-select type="text"  v-model="dispositivo" :items="listadedispositivos"
                        item-value="id" item-text="nome" data-cy="filtro-status" return-object style="width:100%!important;padding-bottom:0px;" >
                    </v-select>
                <!-- <v-text-field style="color: black;"
                  ref="username"
                  v-model="username"
                  :rules="[() => !!username || 'Este campo é obrigatório']"
                  prepend-icon="mdi-account"
                  label="Login"
                  placeholder="Usuário ou email"
                  data-cy="username"
                  required
                />
                <v-text-field
                  ref="password"
                  v-model="password"
                  :rules="[() => !!password || 'Este campo é obrigatório']"
                  :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                  :type="showPassword ? 'text' : 'password'"
                  prepend-icon="mdi-lock"
                  label="Senha"
                  placeholder="*********"
                  counter
                  required
                  data-cy="input-password"
                  @keydown.enter="login"
                  @click:append="showPassword = !showPassword"
                /> -->
              </v-form>
              
            </v-card-text>
            <v-divider class="mt-4" />
            <v-card-actions class="m-2 ">
              <v-btn
                align-center
                justify-center
                class="v-btn light-blue ma-2"
                style="width:40%!important;color:#fff;font-weight:bold;"
                large
                color="default"
                @click="direcionarlogin"
                data-cy="btn-login"
                >Voltar Login
              </v-btn>
              <v-spacer />
              <v-btn
                align-center
                justify-center
                class="v-btn light-blue ma-2"
                style="width:50%!important;color:#fff;font-weight:bold;"
                large
                color="primary"
                @click="login"
                data-cy="btn-login"
                >Entrar
              </v-btn>
            </v-card-actions>
            <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
                <div style="display:flex;align-items:center;align-content:center;justify-content:space-between;">
                  <span>{{ snackText }}</span>
                  <v-btn style="background-color:white;color:grey;" @click="snack = false">FECHAR</v-btn>
                </div>
            </v-snackbar>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    <v-container>
      <v-layout align-center justify-center>
        <v-flex md4>
<!--           <a href="#">
            <img src="/img/logo_p-1.png" width="150" height="50" />
          </a> -->
          <v-spacer/>
          <span class="font-weight-light copyright">
            &copy; 
            {{ new Date().getFullYear() }}
            <a href="https://economysoftware.com.br" target="_blank"
              >@economysoftware</a
            >
          </span>
        </v-flex>
      </v-layout>
    </v-container>
  </v-main>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      username: "",
      password: "",
      snackText: "Dados inválidos",
      snackColor: 'warning',
      snack: false,
      color: "general",
      showPassword: false,
      statusreserva:{},
      listadedispositivos:[],
      dispositivo:{}
    };
  },

  // Sends action to Vuex that will log you in and redirect to the dash otherwise, error
  methods: {
    direcionarlogin(){
        this.$router.push("/")
    },
    login: function () {
      setTimeout(() => {}, 1000);
      if (this.username.length < 1 || this.password.length < 1) {
        this.snackText = "Verifique usuário e/ou senha";
        this.snackColor = 'warning';
        this.snack = true;
        setTimeout(() => {
          this.snack = false;
        }, 3000);

        return;
      }
      let username = this.username;
      let password = this.password;
      this.$store
        .dispatch("login", { username, password })
        .then((response) => {
          if (response.status == 200) {
            this.$router.push("/dashboard")
          } else {
            console.log(JSON.stringify(response))
          }
        })
        .catch((err) => {
          console.log(err);
          this.snackText = "Dados inválidos";
          this.snackColor = 'warning';
          this.snack = true;
          setTimeout(() => {
            this.snack = false;
          }, 3000);
        });
    },
  },
  mounted(){
        this.listadedispositivos = [
            {id:1,nome:"Dispositivo A"},
            {id:2,nome:"Dispositivo B"},
            {id:3,nome:"Dispositivo C"},
            {id:4,nome:"Dispositivo D"},
            {id:5,nome:"Dispositivo E"},
            {id:6,nome:"Dispositivo F"},
            {id:7,nome:"Dispositivo G"},
            {id:8,nome:"Dispositivo H"},
        ]
        this.dispositivo = {id:1,nome:"Dispositivo A"}
  },
  metaInfo() {
    return {
      title: "Ficha Digital | Login",
    };
  },
};
</script>
